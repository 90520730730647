.App {
  text-align: center;
}

::-webkit-scrollbar
{
width: 0;
height: 1.2rem;
}


