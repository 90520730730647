

.D6_Home_SC_Slider_Div{
    height: 60vh !important;
}
.D6_Home_SC_Slider_Div .slick-next {
    right: 0px !important;
}
.D6_Home_SC_Slider_Div .slick-slide img{
    /* width: 100% !important; */
    /* height: 630px !important; */
    /* height:100vh !important;
    width: 100% !important; */


    height: 58vh !important;
    width: 100vw !important;
 
}
.D6_Home_SC_Slider_Div .slick-slide .blog_bgC{
   
    height: auto;
    min-height: 58vh !important;
    width: 100vw !important;
    /* background: url(../Images/AboveFooter.jpg); */
    background-size: 100% 100%;
    background-position: top center;
    background-repeat: no-repeat;
    align-content: center;

}

.D6_Home_SC_Slider_Div .slick-slide .max-width{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    max-width: unset;
    cursor: pointer;
}

.D6_Home_SC_Slider_Div .slick-slide .crousel-content{
    position: absolute;
    top: 35%;
    left: 60px;
    z-index: 2;
    /* transform: translateY(-50%); */
    z-index: 9;
    max-width: 75%;
    text-align: left;
    filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
}


.D6_Home_SC_Slider_Div .slick-slide .crousel-content h2{
    font-style: normal;
    color: #ffffff;
    font-weight: bold;
    font-size: 22px;
    line-height: 38px;
    letter-spacing: 1.1px;
    margin-bottom: 14px;
    position: relative;

}


.D6_Home_SC_Slider_Div .slick-slide .crousel-content p{
    color: #ffffff;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1px;
    position: relative;

}
.D6_Home_SC_Slider_Div .slick-slide .crousel-content p span{
    color: #ffffff;
    font-weight: 600;
    font-size: 19px;
    
    }
   

    
.D6_Home_SC_Slider_Div .slick-next:before {
    content: '' !important;
}
.D6_Home_SC_Slider_Div .slick-dots {
    bottom: -10px !important;
}
.D6_Home_SC_Slider_Div .slick-dots li{
    width:50px;
}
.D6_Home_Button {
    color: var(--gcolor);
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 22px;
    border: 1px solid var(--ycolor);
    background-color: var(--ycolor);
    cursor: pointer;
    border-radius: 5px;
}
.D6_Home_SC_Slider_Div .slick-dots li button:before {
    width: 50px !important;
    height: 5px !important;
    color: #ffffff !important;
    background: #e1163c !important;
    content: '' !important;
    border-radius: 5px;
    margin-top: 20px;
}
.D6_SC_SliderDiv {
    margin-left: 100px;
    width: 200px;
    height: 150px;
    position: absolute;
    /* bottom: 220px; */
}
.D6_SC_SliderDivH1 {
    font-size: 41px;
    color: white;
    padding-bottom: 10px;
}
.D6_SC_SliderDivH2 {
    font-size: 21px;
    font-weight: normal;
    color: white;
}
.D6_Home_SC_Slider_Div .prevArrow {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 100;
    cursor: pointer;
    font-size: 42px;
    color:white;
  }
  
  .D6_Home_SC_Slider_Div .nextArrow {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 100;
    cursor: pointer;
    font-size: 42px;
    color:white;
  }

.D6_Home_Img1_div1 {
    text-align: -webkit-center !important;
}
.papper_inner{
    min-height: 319px;
    width: 40%;
    margin-top: 35vh;
    float: left;
    margin-left: 8vw;
    margin-bottom: 10vh;
    height: auto;
    cursor: pointer;
}
.papper_mainHead{
    text-align: start;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 3vh;
    font-size: 17px !important;
    font-weight: 600 !important;
    color: var(--redcolor);
}
.papper_mainHeadtitle{
    text-align: start;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-right: 10px;
    padding-top: 1vh;
    font-size: 21px !important;
    font-weight: 700 !important;
    color: var(--redcolor);
}
.papper_desc{
    text-align: start;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vh;
    padding-right: 10px;
    font-size: 20px !important;
    color: var(--greycolorshade);
}
.papper_rm{
    padding-top: 5vh;
    font-size: 20px !important;
    color: var(--greycolorshade);
    border-bottom-style: solid;
    width: 25%;
    margin-bottom: 2vh !important;
    border-bottom-color: var(--redcolor);
}
@media (max-width: 768px) {
    .D6_Home_SC_Slider_Div .slick-slide img{
        width: 100% !important;
        /* height: 400px !important; */
        height: 100% !important;

    }
    .D6_SC_SliderDiv {
        margin-left:20px;
        bottom: 160px;
    }
    .D6_Home_SC_Slider_Div .prevArrow {
        font-size: 24px;
      }
      .D6_Home_SC_Slider_Div .nextArrow {
        font-size: 24px;
      }
      .D6_Home_Button {
        padding: 5px 10px;
        font-size: 18px;
      }
}
@media (max-width: 600px) {
    
.D6_Home_SC_Slider_Div{
    height: 70vh !important;
}
    .D6_Home_SC_Slider_Div .slick-slide img{
        width: 100% !important;
        height: 100% !important;
    }
   
    .D6_SC_SliderDiv {
        margin-left:20px;
        bottom: 85px;
    }
    .D6_SC_SliderDivH1 {
        font-size: 24px;
        margin-top:20px;
        width: 150px;
    }
    .D6_SC_SliderDivH2 {
        font-size: 16px;
        font-weight: normal;
        color: white;
    }
    .D6_Home_Button {
        padding: 5px 5px;
        font-size: 16px;
    }
    .D6_Home_SC_Slider_Div .prevArrow {
        display:none;
      }
      .D6_Home_SC_Slider_Div .nextArrow {
        display:none;
      }
}
@media (max-width: 500px) {
    .D6_Home_SC_Slider_Div .slick-slide .crousel-content {
        position: absolute;
        top: 35%;
        left: 15px;
        z-index: 2;
        /* transform: translateY(-50%); */
        z-index: 9;
        max-width: 85%;
        text-align: left;
        filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
    }
    .D6_Home_SC_Slider_Div .slick-slide .blog_bgC{
   
        height: auto;
        min-height: 65vh !important;
        width: 100vw !important;
        /* background: url(../Images/AboveFooter.jpg); */
        background-size: 100% 100%;
        background-position: top center;
        background-repeat: no-repeat;
        align-content: center;
    
    }
    .D6_SC_SliderDiv {
        bottom: 65px;
    }
    .papper_inner {
        width: 80%;
    }
    .papper_rm {
       
        width: 50%;
        
    }
}
