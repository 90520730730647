.bg_img{
    width: 100%;
    height: 100%;
    /* background-image: url('../Images/Indus.jpg'); */
    /* background: linear-gradient(rgba(99,192,214,0.8),rgba(0,0,0,0.8)), url('../../images/Filly4.jpg');   */
   
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: right;
    background-position: bottom;
}
.ulComm1{
   
    padding-left: 86px;
    padding-bottom: 30px;
    padding-top: 20px;
    text-align: start;
    color: white;
    font-size: 24px !important;

  }

  .D6_ADS_G1_DivPComm_B1  {
      padding-top: 12px;
  }
  .heading_contact{
    color: white;
    font-size: 35px !important;
    padding-top: 200px;
    font-weight: 800;
    padding: 270px 0px 0px 40px;
  }
  .image_below{
    background:  url('../../images/business.jpg');  
    width: 100%;
    height: 300px;
    width: 80%;
    border-radius: 10px;
    /* padding: 20px; */
    margin-left: 50px;
    margin-bottom: 20px;
    /* padding-top: 100px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-position: bottom;
    cursor: pointer;

  }
  .grid_form{
    margin-left: 165px !important;
    background: white;
    border-radius: 20px;
    margin-top: 180px !important;
    /* margin-right: 0px !important; */
    /* width: 183px !important; */
    max-width: 422px !important;
    margin-left: padding;
    margin-bottom: 30px !important;

  }
  @media (max-width:500px) {
    .grid_form{
      margin-left: 0px !important;
      margin-top: 40px !important;
      max-width: 340px !important;
      margin-bottom: 30px;
      
    }
  .grid_container {
    justify-content: center;
  }
  .heading_contact{
    color: white;
    font-size: 33px !important;
    padding-top: 200px;
    font-weight: 800;
    padding: 270px 0px 0px 35px;
  }
  .ulComm1{
   
    padding-left: 56px;
    padding-bottom: 30px;
    padding-top: 20px;
    text-align: start;
    color: white;
    font-size: 21px !important;

  }
  .image_below{
    width: 90%;
    margin-left: 2.2vh;
  }
  }