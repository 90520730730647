.BG_imgB{
    width: 100%;
    height:550px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: right;
    background-position: bottom;
}
.heading_Q{
    font-size: 21px !important;
    font-weight: bolder !important;
    min-height: 70px;
}
.heading_A{
    font-size: 19px !important;
    padding-top: 30px !important;
    font-weight: lighter !important;
    min-height: 195px;
    max-height: 200px;

}
.read_more{
    color: #52B9D1 !important;
    padding-right: 5px;
    cursor: pointer;
}
.most_popular{
    padding-right: 10px;
    font-size: 18px !important;
    padding-top: 20px;
    padding-bottom: 20px;
}
.most_popularT{
    padding-right: 10px;
    font-size: 16px !important;
}
.most_popularV{
    padding-right: 10px;
    font-size: 15px !important;
}
.small_grid{
  box-shadow:   0px 0px 9px 9px #f2f2f2;
  height: fit-content !important;

}
.small_gridM{
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    padding-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 1px 1px #f2f2f2;
}
.grid_blog{
    padding-right:90px !important;
}
.MuiImageListItem-img{
    height: 200px !important;
}
.MuiImageList-root{
height: auto !important;
}
.MuiImageListItem-root{
    height: 250px !important;
}
.value_grid{
    padding-left: 30px;
    padding-top: 30px;
    min-height: 270px ;
}

@media (max-width:500px) {
    .heading_Q{
        padding-top: 10px;
    }
    .grid_blog{
        padding-right:0px !important;
    }
    .small_gridM{
        justify-content: center;
        align-items: center;
        padding-left: 0px;

        padding-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        box-shadow: 0px 2px 1px 1px #f2f2f2;
    }   
    .most_popularT {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 16px !important;
        padding-top: 10px;}
        .BG_imgB{
            
            height:500px;
            
        }
        .value_grid{
            padding-left: 0px;
        }
        .heading_A{
       
            min-height: auto;
            max-height: 200px;
        
        }
}